const md5 = require('md5');
/**
 * 计算文章评论 skey
 * @param {string} title 文章标题
 * @param {string} pcUrl 文章 pcUrl
 */
const getSkey = (title, pcUrl) => {
    const str = `Ifeng888${encodeURI(title)}${encodeURI(pcUrl)}`;
    const skey = md5(str);

    return skey.substr(2, 6).toLowerCase();
};

/**
 * 处理新闻时间
 * @param {string} time 新闻时间
 */
const handleNewstime = time => {
    try {
        if (!time) {
            return time;
        }

        const d = new Date();

        time = time.substr(0, time.length - 3);
        const year = Number(time.split('-')[0]);

        if (year < d.getFullYear()) {
            return time;
        } else {
            return time.substr(5, time.length);
        }
    } catch (e) {
        console.error(e);

        return time;
    }
};

/**
 * 一维数组转二维数组
 */
const oneChangeTwoDimensional = (arr, num) => {
    const result = [];

    for (let i = 0, len = arr.length; i < len; i += num) {
        const item = arr.slice(i, i + num);

        result.push(item);
    }

    return result;
};

/**
 * 判断是否为数组
 * @param {*} originData
 */
const isArray = originData => {
    return Object.prototype.toString.call(originData) === '[object Array]';
};

/**
 * 格式化播放次数
 * @param {string} playTimes 播放次数
 */
const formatPlayTimes = playTimes => {
    let playTimesNum = Number(playTimes);

    if (playTimes > 10000) {
        playTimesNum = `${Math.ceil(playTimesNum / 1000) / 10}万`;
    }

    return `${playTimesNum}次播放`;
};

/**
 * 补零
 * @param {Number} number
 */
const coverZero = number => {
    return number.toString().length === 2 ? number.toString() : `0${number.toString()}`;
};

/**
 * 格式化视频时长
 * @param {String} sec
 */
const formatVideoDuration = sec => {
    let seconds = sec;

    if (typeof sec === 'string') {
        seconds = Number(sec);
    }

    let minutes = Math.floor(seconds / 60);
    const sec_left = seconds % 60;
    let hours = 0;

    if (minutes > 60) {
        hours = Math.floor(minutes / 60);
        minutes %= 60;
    }

    return hours > 0 ? `${hours.toString()}:${coverZero(minutes)}:${coverZero(sec_left)}` : `${coverZero(minutes)}:${coverZero(sec_left)}`;
};

const isfastmode = () => {
    return (() => {
        let isfastmode = 1;

        try {
            localStorage.setItem('checkfastmode', 1);
            isfastmode = 0;
        } catch (e) {
            console.error(e);
        }

        return isfastmode;
    })();
};

const setlocal = (key, value) => {
    if (isfastmode()) return '';
    localStorage.setItem(key, JSON.stringify(value));
};

const getlocal = key => {
    if (isfastmode()) return '';

    return JSON.parse(localStorage.getItem(key));
};

const getYMD = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;

    month = month < 10 ? '0' + month : month;
    let day = currentDate.getDate();

    day = day < 10 ? '0' + day : day;

    return parseInt(year + month + day);
};

export { getSkey, handleNewstime, isArray, formatPlayTimes, formatVideoDuration, setlocal, getlocal, getYMD };
