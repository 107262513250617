import React from 'react';
import Ad from '@ifeng-fe/ui_pc_ad';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Chip from 'Chip';
import styles from './index.css';
import Header from './header';
import PageNav from './pageNav';
import Slide from './slide';
import Channel from './channel';
import AppRecommend from './appRecommend';
import LifeZone from './lifeZone';
import Footer from './footer';
import FixSlide from './fixSlide';
import GoTopBottom from './goTopBottom';
import AddDesktop from './addDesktop';
import Redbag from './redbag';
import Bookrack from './bookrack';
import BrowserGuide from './browserGuide';
import ChipView from './chipView';
import NoSSR from 'react-no-ssr';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import { hasSecretCallup } from './callupCondition';
import { getAtomicService } from '@src/services/api';
import { setlocal, getlocal, getYMD } from '@src/services/utils';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        isSecretCallup: false,
    };

    UNSAFE_componentWillMount() {
        const { secretCallup } = this.props.content;

        if (typeof document === 'object' && secretCallup && hasSecretCallup()) {
            this.setState({
                isSecretCallup: true,
            });
        }
    }

    componentDidMount() {
        this.pageClickCount();
        this.callAtomicService();
    }

    pageClickCount = () => {
        document.getElementsByTagName('body')[0].addEventListener(
            'click',
            event => {
                if (event && event.target && event.target.tagName === 'A' && navigator && navigator.sendBeacon) {
                    const data = {
                        namespace: 'shank',
                        appname: 'web_channel_spring',
                        route: '/click//mobile/i/(index)?',
                        url: event.target.getAttribute('href') || '',
                    };

                    navigator.sendBeacon('https://collect.ifengcloud.ifeng.com/v1/proxy/hb', JSON.stringify(data));
                }
            },
            false,
        );
    };

    callAtomicService = async () => {
        document.getElementsByTagName('body')[0].addEventListener(
            'click',
            async event => {
                const browseUA = navigator.userAgent.toLocaleLowerCase();
                const huaweiSignList = ['openharmony', 'huaweibrowser', 'phone', 'arkweb'];
                let target = event.target;

                while (target && target.tagName !== 'A') {
                    target = target.parentNode;
                }
                const feedUrl = target?.getAttribute?.('href') || '';
                const isClick = getlocal('atomicServiceClickDate') === getYMD();

                setlocal('atomicServiceClickDate', getYMD());
                if (!isClick && target?.tagName === 'A' && feedUrl?.includes?.('ifeng.com/c/') && huaweiSignList.every(keyword => browseUA.includes(keyword))) {
                    event.preventDefault();

                    try {
                        const shortLink = await getAtomicService(feedUrl);

                        if (shortLink) {
                            if (window?.BJ_REPORT?.report) {
                                window.BJ_REPORT.report(new Error('请求成功'), false, 61);
                            }
                            window.open(shortLink, '_blank');
                        } else {
                            window.BJ_REPORT.report(new Error('请求失败 短连接返回异常'), false, 62);

                            location.href = feedUrl;
                        }
                    } catch (e) {
                        window.BJ_REPORT.report(new Error('请求失败 短连接请求异常'), false, 63);
                        location.href = feedUrl;
                    }
                } else if (feedUrl) {
                    location.href = feedUrl;
                }
            },
            false,
        );
    };

    render() {
        const { content } = this.props;
        const { isSecretCallup } = this.state;
        const {
            navigation,
            slidePic,
            footerInfo,
            appRecommend,
            dropDownAd,
            stickBannerAd,
            slideAd,
            lifeAd,
            redbag,
            headerRedTheme,
            bookrack,
            guideConfig,
            winterOlympicsCopyright,
            fullScreenAd,
        } = content;
        const slideData = {
            slidePic,
            slideAd,
        };
        const headerConfig = {
            headerRedTheme,
            winterOlympicsCopyright,
        };

        return (
            <React.Fragment>
                <NoSSR>
                    {/* 新版浮动富媒体-顶部下推广告 */}
                    <Ad content={dropDownAd} />
                    {/* 置顶banner广告位 */}
                    <Ad content={stickBannerAd} />
                </NoSSR>

                <Chip id="170058" type="struct" title="页头飘红" groupName="content" content={headerConfig} translate="handleHomeRedHeader">
                    <Header />
                </Chip>
                <PageNav content={navigation} />
                <NoSSR>
                    <Slide content={slideData} isSecretCallup={isSecretCallup} />
                </NoSSR>
                <Channel content={content} isSecretCallup={isSecretCallup} />
                <NoSSR>
                    <AppRecommend content={appRecommend} />
                </NoSSR>
                {/* <NoSSR>
                    <LifeZone ad={lifeAd} />
                </NoSSR> */}
                <Footer content={footerInfo} />
                <FixSlide />
                <GoTopBottom />
                <AddDesktop />
                <NoSSR>
                    <Redbag content={redbag} />
                </NoSSR>
                <NoSSR>
                    <Bookrack content={bookrack} />
                </NoSSR>
                <NoSSR>
                    <BrowserGuide content={guideConfig} />
                </NoSSR>
                <NoSSR>
                    <ChipView />
                </NoSSR>
                <NoSSR>
                    {/* 全屏浮层广告 */}
                    <Ad content={fullScreenAd} />
                </NoSSR>
                <ChipEdit transform={transform} />
            </React.Fragment>
        );
    }
}

export default errorBoundary(Layout);
